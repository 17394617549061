import { sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink } from "firebase/auth";
import { useState, useEffect } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [error, setError] = useState("");
  const [fromLink, setFromLink] = useState(true);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      navigate("/profile");
    } else {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const email = window.localStorage.getItem("emailForSignIn");
        if (email) {
          signInWithEmailLink(auth, email, window.location.href).then(() => {
            window.localStorage.removeItem("emailForSignIn");
            window.history.replaceState(null, "", window.location.pathname);
          }).catch((error) => {
            setFromLink(false);
            setError("Нэвтрэхэд алдаа гарлаа: " + error.message);
            console.error("Error signing in with email link", error);
          });
        } else {
          setFromLink(false);
          setError("Нэвтрэх холбоос явуулсан төхөөрөмжөөсөө оролдоно уу!");
        }
      } else {
        setFromLink(false);
      }
    }
  }, [user, navigate]);

  const sendSignInLink = async (e) => {
    e.preventDefault();

    setError("");

    if (accepted) {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };

      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        setEmailSent(true);
        window.localStorage.setItem("emailForSignIn", email);
      } catch (error) {
        setError("Нэвтрэх имэйл явуулах үед алдаа гарлаа: " + error.message);
        console.error("Error sending sign-in email link", error);
      }
    } else {
      setError("Үйлчилгээний нөхцлийг хүлээн зөвшөөрнө үү!");
    }
  };

  return (
    <>
      <div className="px-4 container m-auto max-w-[600px] mt-[80px]">
        <div className="text-[#E6E7EB] text-[32px] font-bold mb-[64px]">Altex Investment нэвтрэх</div>
        {fromLink ? <>
          <svg className="block animate-spin mx-auto" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
            <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
          </svg>
        </> : <>
          {emailSent ? (
            <div className="text-[#82879B] text-[14px] mb-[8px]">Таны {email} хаяг руу нэвтрэх холбоос илгээлээ. Та и-мэйлээ шалгаж, холбоос дээр дарж нэвтэрнэ үү.</div>
          ) : (
            <>
              <label htmlFor="email" className="text-[#E6E7EB] mb-[8px]">Имэйл</label>
              <div className="text-[#82879B] text-[14px] mb-[8px]">
                <svg className="inline mr-[8px] mb-[3px]" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_30_251)">
                    <path d="M8.53971 15.6271C12.2216 15.6271 15.2064 12.6424 15.2064 8.96046C15.2064 5.27856 12.2216 2.29379 8.53971 2.29379C4.85782 2.29379 1.87305 5.27856 1.87305 8.96046C1.87305 12.6424 4.85782 15.6271 8.53971 15.6271Z" stroke="#82879B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.53979 6.29379V8.96046" stroke="#82879B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.53979 11.6271H8.5473" stroke="#82879B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_30_251">
                      <rect width="16" height="16" fill="white" transform="translate(0.539795 0.960449)" />
                    </clipPath>
                  </defs>
                </svg>
                Таны и-мэйл <a className="text-[#3973C5] underline" href="https://trade.altex.mn/" target="_blank" rel="noreferrer">trade.altex.mn</a> сайт дээрх хаягтай ижил байх бөгөөд KYC баталгаажуулсан байх ёстойг анхаарна уу.
              </div>
              <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="rounded border border-[#E6E7EB] w-full px-[16px] py-[14px] bg-transparent mb-[8px]" placeholder="you@example.com" required />
              <div className="mb-[24px]">
                <label>
                  <input type="checkbox" className="accent-[#13A9FD]" checked={accepted} onChange={() => setAccepted(!accepted)} /> <Link className="underline" to="/tos">Үйлчилгээний нөхцөл</Link>-ийг зөвшөөрч байна.
                </label>
              </div>
              <button onClick={sendSignInLink} className={`${!accepted && "opacity-50"} block rounded text-[14px] font-bold bg-gradient-to-t from-[#006CFF] to-[#13A9FD] px-[24px] py-[14px] w-full`}>Нэвтрэх линк авах</button>
              {error && <div className="text-red-500 mt-[5px]">{error}</div>}
            </>
          )}
        </>}
      </div>
    </>
  );
};

export default Auth;
