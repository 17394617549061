const assets = {
  SPY: {
    type: "etf",
    symbol: "SPY",
    name: "SPDR S&P 500 ETF TRUST",
    desc: "S&P 500 нь АНУ-н зах зээлийн үнэлгээгээрээ тэргүүлэгч 500 компанийг нэгтгэсэн индекс юм. АНУ-н эдийн засаг тэлж эдгээр компаниуд хөгжихийн хэрээр S&P 500 урт хугацааны өсөлтийн боломжоос ашиг хүртэх боломжтой.",
    info: "https://digital.fidelity.com/prgw/digital/research/quote/dashboard/summary?symbol=SPY",
    tradingview_symbol: "AMEX:SPY"
  },
  XLE: {
    type: "etf",
    symbol: "XLE",
    name: "ENERGY SELECT SECTOR SPDR FUND",
    desc: "Energy Select Sector fund(XLE) нь 1998 онд анх үүссэн бөгөөд SP500 индекс-д багтдаг газрын тос, хий, хэрэглээний түлш, эрчим хүч, эрчим хүчний тоног төхөөрөмж, үйлчилгээ гэсэн салбаруудын компанийг хамардаг ба нийт Energy ETF-ийн ихэнх хувийг дангаараа эзэлдэг хамгийн том эрчим хүчний ETF юм.",
    info: "https://digital.fidelity.com/prgw/digital/research/quote/dashboard/summary?symbol=XLE",
    tradingview_symbol: "AMEX:XLE"
  },
  FSRNX: {
    type: "etf",
    symbol: "FSRNX",
    name: "Fidelity® Real Estate Index Fund",
    desc: "Fidelity® Real Estate Index Fund нь үл хөдлөх хөрөнгийн хөрөнгө оруулалтын сангууд болон бусад үл хөдлөх хөрөнгөтэй холбоотой хөрөнгө оруулалтын нийт өгөөжтэй тэнцэх хөрөнгө оруулалтын үр дүнг өгөхийг эрмэлздэг. Тус сан нь ихэвчлэн MSCI US IMI үл хөдлөх хөрөнгийн 25/25 индекст багтсан үнэт цаасанд хөрөнгийн 80-аас доошгүй хувийг хөрөнгө оруулалт хийдэг ба уг үнэт цааснуудаа зээлж сангийн орлогоо бүрдүүлдэг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/316146232",
  },
  FPADX: {
    type: "etf",
    symbol: "FPADX",
    name: "Fidelity® Emerging Markets Index Fund",
    desc: "Fidelity Emerging Markets Index Fund нь MSCI Emerging Markets Index-д багтсан үнэт цаас болон индекст багтсан үнэт цаасыг төлөөлөх хадгаламжийн бичигт хөрөнгө оруулах замаар шинээр хөгжиж буй хөрөнгийн зах зээлийн гүйцэтгэлийг хэмжих зорилготой зах зээлийн үнэлгээгээр жигнэсэн индекс юм.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/316146331",
  },
  FBIIX: {
    type: "etf",
    symbol: "FBIIX",
    name: "Fidelity® International Bond Index Fund",
    desc: "Fidelity® International Bond Index Fund нь хөрөнгийнхөө 80-с доошгүй хувийг Bloomberg Global Aggregate-н төрөлжүүлсэн индекс-д хөрөнгө оруулалт хийдэг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/31635T732",
  },
  FIPDX: {
    type: "etf",
    symbol: "FIPDX",
    name: "Fidelity® Inflation-Protected Bond Index Fund",
    desc: "Fidelity® Inflation-Protected Bond Index Fund нь АНУ-н инфляцаас хамгаалагдсан үнэт цаасны индекс хөрөнгийн 80-с доошгүй хувийг хөрөнгө оруулалт хийдэг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/31635T104",
  },
  FFNOX: {
    type: "etf",
    symbol: "FFNOX",
    name: "Fidelity® Multi-Asset Index Fund",
    desc: "Fidelity® Multi-Asset Index Fund нь индекст суурилсан өргөн хүрээний төрөлжүүлсэн хөрөнгө оруулалтыг эрэлхийлдэг хөрөнгө оруулагчдад зориулсан бөгөөд хувьцаа болон бондод хөрөнгө оруулалт хийдэг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/31634R109",
  },
  FITLX: {
    type: "etf",
    symbol: "FITLX",
    name: "Fidelity® U.S. Sustainability Index Fund",
    desc: "Fidelity® US Sustainability Index Fund нь хөрөнгийнхөө 80-с доошгүй хувийг MSCI USA ESG Leaders Index-д хөрөнгө оруулдаг ба энэ нь АНУ-н томоохон болон дунд хэмжээний хөрөнгө оруулалттай компаниудын хувьцааны гүйцэтгэлийг илэрхийлдэг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/31635V398",
  },
  FSPCX: {
    type: "etf",
    symbol: "FSPCX",
    name: "Fidelity® Select Insurance Portfolio",
    desc: "Fidelity® Select Insurance Portfolio нь хөрөнгийнхөө 80-с доошгүй хувийг андеррайтинг, давхар даатгал, амь нас, эрүүл мэндийн даатгалын үйл ажиллагаа эрхэлдэг компаниудад хөрөнгө оруулдаг. Тус сан нь үндсэндээ энгийн хувьцаанд хөрөнгө оруулалт хийдэг. Энэ нь хөрөнгө оруулалтыг сонгохдоо үнэт цаас гаргагч бүрийн санхүүгийн байдал, салбарын байр суурь, зах зээл, эдийн засгийн нөхцөл зэрэг хүчин зүйлсийн үндсэн шинжилгээг ашигладаг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/316390541",
  },
  FSPTX: {
    type: "etf",
    symbol: "FSPTX",
    name: "Fidelity® Select Technology Portfolio",
    desc: "Fidelity® Select Technology Portfolio нь хөрөнгийнхөө 80-с доошгүй хувийг технологийн бүтээгдэхүүн, үйл явц үйлчилгээг санал болгох, ашиглах, хөгжүүлэх чиглэлээр голчлон үйл ажиллагаа явуулдаг компаниудад хөрөнгө оруулалт хийдэг. Тус сан нь хөрөнгө оруулалт хийхдээ үнэт цаас гаргагчийн санхүүгийн байдал, салбарын байр суурь, зах зээл, эдийн засгийн нөхцөл зэрэг хүчин зүйлсийн үндсэн шинжилгээг ашигладаг.",
    info: "https://fundresearch.fidelity.com/mutual-funds/summary/316390202",
  },
  BOC24: {
    type: "bond",
    symbol: "BOC24",
    name: "BANK OF CHINA LTD.",
    desc: "Bank of China нь дэлхий даяар үйлчилгээ үзүүлдэг томоохон банк ба тус бонд нь Сингапурын Bank of China Limited банкнаас 2022 оны 4 сарын 28-нд 3 жилийн хугацаатайгаар гаргасан тогтмол купонтой бонд юм.",
    info: "https://markets.businessinsider.com/bonds/3_250-bank-of-china-bond-2025-xs2470956322?miRedirects=1",
  },
  US24: {
    type: "bond",
    symbol: "US24",
    name: "UNITED STATES OF AMERICADL-NOTES 2014(24)",
    desc: "АНУ-н 2014 оны 8-р сарын 15-нд гаргасан засгын газрын бонд бөгөөд жилд 2 удаа купон төлөгддөг бонд юм.",
    info: "https://markets.businessinsider.com/bonds/united_states_of_americadl-notes_201424-bond-2024-us912828d564",
  },
  // PHIL27: {
  //   type: "bond",
  //   symbol: "PHIL27",
  //   name: "PHILLIPS 66 CO.DL-NOTES 2023(23/27)",
  //   desc: "",
  //   info: "https://markets.businessinsider.com/bonds/phillips_66_codl-notes_202323-27-bond-2027-us718547at95",
  // },
};

export default assets;
