import { useCallback, useEffect, useState } from "react"
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { collection, doc, getDoc, getDocs, limit, orderBy, query } from "firebase/firestore";
import { Link, useParams } from "react-router-dom";
import Requests from "./Requests";
import Executed from "./Executed";
import TradingViewWidget from "./TradingViewWidget";
import Modal from "./Modal";
import assets from "../assets";

const Balance = ({balanceSet, etfSet, refresh}) => {
  const [user] = useAuthState(auth);
  const [balance, setBalance] = useState();
  const [userData, setUserData] = useState();
  const [marketData, setMarketData] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [type, setType] = useState("etf");
  const [selected, setSelected] = useState(Object.keys(assets)[0]);
  const {symbol} = useParams();

  const displayBalance = () => {
    const q = query(collection(db, "marketData"), orderBy("timestamp", "desc"), limit(1));
    Promise.all([
      getDocs(q),
      getDoc(doc(db, "users", user.uid)),
    ]).then(([marketDataSnapshot, userDoc]) => {
      const marketDataDoc = marketDataSnapshot.docs[0];

      if (!marketDataDoc) return setError("Market data not found!");
      if (!userDoc.exists()) return setError("User not found!");

      const userData = userDoc.data();
      const marketData = marketDataDoc.data();

      setMarketData(marketData)
      setUserData(userData)
      setVerified(userData.verified);

      const blnc = Math.floor(userData.USDT * 10**8) / 10**14;
      setBalance(blnc.toFixed(2));

      balanceSet && balanceSet(blnc);
      etfSet && etfSet(userData[symbol] || 0);
    }).catch((e) => {
      setError("Алдаа гарлаа!");
      console.error(e);
    });
  };

  const getBalance = useCallback(() => {
    if (!loading) {
      const functions = getFunctions();
      setLoading(true);
      const getBalanceFunction = httpsCallable(functions, 'getBalance');
      getBalanceFunction().then(() => {
        displayBalance();
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBalance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    displayBalance();
    getBalance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    displayBalance();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [symbol]);

  useEffect(() => {
    setSelected(Object.keys(assets).filter((key) => assets[key].type === type)[0]);
  }, [type]);

  return (
    <>
      {/* <Modal isOpen={true}>
        <div className="max-w-[720px] mt-[50%]">
          hello
        </div>
      </Modal> */}
      <div className="px-4 container m-auto max-w-[1140px]">
        {verified ? <>
          <div className="text-[#E6E7EB] text-[24px] font-bold mb-[24px]">Миний портфолио</div>

          <div className="flex rounded-full border-[2px] border-[#505772] text-[#505772] text-[18px]">
            <div className={`flex-1 text-center rounded-full relative m-[-2px] font-bold p-[5px] border-[2px] ${type === "etf" ? "border-[#15a9fd] text-[#15a9fd]" : "border-transparent cursor-pointer"}`} onClick={() => setType("etf")}>ETF</div>
            <div className={`flex-1 text-center rounded-full relative m-[-2px] font-bold p-[5px] border-[2px] ${type === "bond" ? "border-[#15a9fd] text-[#15a9fd]" : "border-transparent cursor-pointer"}`} onClick={() => setType("bond")}>BOND</div>
          </div>

          <div className="flex flex-wrap gap-[8px] mt-[40px] mb-[24px]">
            {Object.keys(assets).filter((key) => assets[key].type === type).map((asset) =>
              <div key={asset} onClick={() => setSelected(asset)} className={`${selected === asset ? "bg-[#13A9FD]" : "bg-[#0A557F]"} font-bold py-[4px] px-[10px] rounded text-[14px] cursor-pointer`}>
                {asset}
              </div>
            )}
          </div>

          <div className="grid sm:grid-cols-2 gap-[24px] mt-[15px] rounded-[16px]">

            <div className="rounded-[16px] bg-gradient-to-br from-[#101C47] to-[rgba(19,169,253,.25)] p-[16px] text-white flex-1 relative flex flex-col">
              <svg className={`${loading ? "animate-spin" : ""} absolute top-[16px] right-[16px]`} onClick={getBalance} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill="#040C2B" />
                <path d="M27.9125 12.0886C26.8112 10.9789 25.4745 10.1303 24.0009 9.60569C22.5273 9.08104 20.9546 8.89369 19.3988 9.05747C14.577 9.54298 10.6092 13.4533 10.0705 18.269C9.34789 24.6331 14.2748 30 20.4893 30C22.4733 30 24.4166 29.4378 26.0935 28.3786C27.7703 27.3194 29.1118 25.8068 29.9621 24.0164C30.3825 23.1373 29.7519 22.1269 28.7796 22.1269C28.2935 22.1269 27.8336 22.3893 27.6234 22.8223C26.8606 24.4613 25.5559 25.7878 23.9288 26.5789C22.3017 27.3699 20.4514 27.5771 18.6893 27.1657C15.7726 26.5227 13.4208 24.1476 12.8033 21.2346C12.5437 20.0828 12.5467 18.8875 12.8121 17.7371C13.0775 16.5866 13.5984 15.5105 14.3364 14.5882C15.0743 13.666 16.0104 12.9213 17.0755 12.4091C18.1405 11.8969 19.3072 11.6304 20.4893 11.6294C22.6702 11.6294 24.6147 12.5348 26.0337 13.9651L24.0498 15.9465C23.2221 16.7731 23.8001 18.1903 24.9695 18.1903H29.6862C30.4088 18.1903 31 17.5998 31 16.8781V12.1674C31 10.9995 29.5811 10.409 28.7533 11.2357L27.9125 12.0886Z" fill="#505772" />
              </svg>
              <div className="font-bold text-[#13A9FD]">Үлдэгдэл</div>
              <div className="flex-1">
                <span className="text-[24px] font-semibold mr-[4px]">{balance}</span>
                <span className="font-semibold text-[#4F5A87]">USDT</span>
              </div>
              <div className="flex gap-[17px] mt-[10px]">
                <Link to="/deposit" className="rounded-[4px] font-semibold text-[14px] bg-[rgba(255,255,255,.08)] px-[13px] py-[10px] flex-1 text-center">+  Орлого</Link>
                <Link to="/withdraw" className="rounded-[4px] font-semibold text-[14px] bg-[rgba(0,0,0,.16)] px-[13px] py-[10px] flex-1 text-center">-  Зарлага</Link>
              </div>
            </div>

            <AssetCard symbol={selected} userData={userData} marketData={marketData} />
          </div>
        </> : <>
          <div className="max-w-md mx-auto py-8">
            <div className="bg-blue-100 p-4 rounded-lg shadow-md flex items-center space-x-4">
              <span className="text-blue-400">Таны бүртгэл баталгаажаагүй байна! Таны имэйл хаяг <a className="underline font-bold" href="https://trade.altex.mn/">trade.altex.mn</a> дээр бүртгүүлж, KYC хийсэн байх шаардлагатай.</span>
            </div>
          </div>
        </>}

        <div className="flex flex-col md:flex-row gap-[15px]">
          <div className="flex-1">
            <Requests refresh={refresh} />
          </div>
          <div className="flex-1">
            <Executed />
          </div>
        </div>

        {error && <div className="text-red-500">{error}</div>}
      </div>
    </>
  )
}

const AssetCard = ({symbol, userData, marketData}) => {
  const [etfsAsUSDT, setEtfsAsUSDT] = useState();
  const [etfs, setEtfs] = useState();
  const [locked, setLocked] = useState();
  const [price, setPrice] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (userData && marketData) {
      const etf = parseFloat(userData[symbol] || 0);
      const etfusdt = (userData[symbol] || 0) * (marketData[symbol] || 0);
      setPrice(marketData[symbol]);
      setEtfsAsUSDT(etfusdt.toFixed(2));
      setEtfs(parseFloat(etf.toFixed(16)));
      setLocked(Math.floor((userData[`lockedFor${symbol}`] || 0) / 10**4) / 10**2);
    }
  }, [marketData, symbol, userData]);

  return <>
    {assets[symbol].tradingview_symbol && <>
      <Modal isOpen={modalOpen} setIsOpen={setModalOpen}>
        <div className="h-[80vh]">
          <TradingViewWidget symbol={assets[symbol].tradingview_symbol} />
        </div>
      </Modal>
    </>}

    <div className="rounded-[16px] bg-gradient-to-br from-[#101C47] to-[rgba(254,103,3,.25)] p-[16px] text-[#E6E7EB] flex-1 relative flex flex-col">
      <div className="flex justify-between gap-[5px]">
        <div className="text-[#b3b5c1]"><span className="font-bold text-[#FE6703]">{symbol}</span> ({assets[symbol].name})</div>
        <div className="flex flex-wrap gap-[12px] justify-end">
          {assets[symbol].tradingview_symbol && <>
            <svg width="30" height="24" viewBox="0 0 30 24" onClick={() => setModalOpen(true)} fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="29" height="23" rx="3.5" stroke="white" />
              <rect x="16.6666" y="9.6665" width="7.33333" height="3.33333" transform="rotate(90 16.6666 9.6665)" fill="#FDAE13" />
              <rect x="15.3334" y="7.6665" width="11.3333" height="0.666666" transform="rotate(90 15.3334 7.6665)" fill="#FDAE13" />
              <rect x="11.3334" y="7" width="7.33333" height="3.33333" transform="rotate(90 11.3334 7)" fill="white" />
              <rect x="10" y="5" width="11.5" height="0.666666" transform="rotate(90 10 5)" fill="white" />
              <rect x="22" y="7.6665" width="7.33333" height="3.33333" transform="rotate(90 22 7.6665)" fill="white" />
              <rect x="20.6666" y="5.7666" width="10.7333" height="0.666666" transform="rotate(90 20.6666 5.7666)" fill="white" />
            </svg>
          </>}
          <a href={assets[symbol].info} target="_blank" className="border border-[#e6e7eb] px-[8px] pt-[6px] text-[10px] rounded leading-[10px] h-[24px] whitespace-nowrap" rel="noreferrer">
            <svg className="inline mt-[-2px] mr-[8px]" width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 11C6.65673 11.0008 7.30716 10.8719 7.9139 10.6206C8.52064 10.3692 9.07173 10.0005 9.5355 9.5355C10.0005 9.07173 10.3692 8.52064 10.6206 7.9139C10.8719 7.30716 11.0008 6.65673 11 6C11.0008 5.34327 10.8719 4.69285 10.6206 4.08611C10.3692 3.47937 10.0005 2.92828 9.5355 2.4645C9.07173 1.99951 8.52064 1.63075 7.9139 1.37943C7.30716 1.1281 6.65673 0.999153 6 1C5.34327 0.999167 4.69285 1.12812 4.08611 1.37944C3.47937 1.63077 2.92828 1.99952 2.4645 2.4645C1.99952 2.92828 1.63077 3.47937 1.37944 4.08611C1.12812 4.69285 0.999167 5.34327 1 6C0.999153 6.65673 1.1281 7.30716 1.37943 7.9139C1.63075 8.52064 1.99951 9.07173 2.4645 9.5355C2.92828 10.0005 3.47937 10.3692 4.08611 10.6206C4.69285 10.8719 5.34327 11.0008 6 11Z" stroke="white" stroke-linejoin="round" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.75049C6.16576 2.75049 6.32473 2.81634 6.44194 2.93355C6.55915 3.05076 6.625 3.20973 6.625 3.37549C6.625 3.54125 6.55915 3.70022 6.44194 3.81743C6.32473 3.93464 6.16576 4.00049 6 4.00049C5.83424 4.00049 5.67527 3.93464 5.55806 3.81743C5.44085 3.70022 5.375 3.54125 5.375 3.37549C5.375 3.20973 5.44085 3.05076 5.55806 2.93355C5.67527 2.81634 5.83424 2.75049 6 2.75049Z" fill="white" />
              <path d="M6.12488 8.5V5H5.62488M5.24988 8.5H6.99988" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            About {symbol}
          </a>
        </div>
      </div>
      <div className="flex my-[16px] relative gap-[50px]">
        <div className="flex-1">
          <div className="flex-grow">
            <span className="text-[24px] font-semibold mr-[4px]">{etfsAsUSDT}</span>
            <span className="font-semibold text-[#4F5A87] mr-[4px]">USDT</span>
            <span className="text-[#4F5A87]">(</span>
            <span className="text-[16px] opacity-60 font-semibold mr-[4px]">{Math.floor(etfs * 10**2)/10**2 || 0}</span>
            <span className="text-[14px] font-semibold text-[#4F5A87]">{symbol}</span>
            <span className="text-[#4F5A87]">)</span>
          </div>
          <div className="text-[12px] text-[#B4B7C3]">
            Үнэ: <strong>{price}</strong> USDT
          </div>
          <div className="text-[12px] text-[#B4B7C3]">
            Ашиг: <strong>{Math.floor((etfsAsUSDT-locked || 0) * 10**2) / 10**2}</strong> USDT <span className={`${(etfsAsUSDT-locked)*100/locked < 0 ? "text-red-500" : "text-green-500"} opacity-50`}>{(etfsAsUSDT-locked)*100/locked < 0 ? "▼" : "▲"}</span><strong>{(((etfsAsUSDT-locked)*100/locked) || 0).toFixed(2)}</strong>%
          </div>
          <div className="text-[12px] text-[#B4B7C3] mb-[16px]">
            Түгжигдсэн: <strong>{locked}</strong> USDT
          </div>
        </div>
        <div className="flex-shrink text-right text-[12px] text-[#B4B7C3]">
          {assets[symbol].desc}
        </div>
      </div>
      <div className="flex gap-[17px] mt-[10px]">
        <Link to={`/buy/${symbol}`} className="rounded-[4px] font-semibold text-[14px] bg-[rgba(255,255,255,.08)] px-[13px] py-[10px] flex-1 text-center">
          <svg className="inline mr-[10px] mb-[4px]" width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.125 12C0.815626 12 0.550688 11.888 0.330188 11.664C0.109688 11.44 -0.000374045 11.171 9.55009e-07 10.8571V5.14286C9.55009e-07 4.82857 0.110251 4.55943 0.330751 4.33543C0.551251 4.11143 0.816001 3.99962 1.125 4H1.6875V2.85714C1.6875 2.06667 1.96181 1.39276 2.51044 0.835429C3.05906 0.278096 3.72225 -0.000380562 4.5 3.9032e-07C5.27812 3.9032e-07 5.9415 0.278667 6.49012 0.836C7.03875 1.39333 7.31287 2.06705 7.3125 2.85714V4H7.875C8.18437 4 8.44931 4.112 8.66981 4.336C8.89031 4.56 9.00037 4.82895 9 5.14286V10.8571C9 11.1714 8.88975 11.4406 8.66925 11.6646C8.44875 11.8886 8.184 12.0004 7.875 12H1.125ZM1.125 10.8571H7.875V5.14286H1.125V10.8571ZM4.5 9.14286C4.80938 9.14286 5.07431 9.03086 5.29481 8.80686C5.51531 8.58286 5.62537 8.3139 5.625 8C5.625 7.68571 5.51475 7.41657 5.29425 7.19257C5.07375 6.96857 4.809 6.85676 4.5 6.85714C4.19063 6.85714 3.92569 6.96914 3.70519 7.19314C3.48469 7.41714 3.37463 7.6861 3.375 8C3.375 8.31429 3.48525 8.58343 3.70575 8.80743C3.92625 9.03143 4.191 9.14324 4.5 9.14286ZM2.8125 4H6.1875V2.85714C6.1875 2.38095 6.02344 1.97619 5.69531 1.64286C5.36719 1.30952 4.96875 1.14286 4.5 1.14286C4.03125 1.14286 3.63281 1.30952 3.30469 1.64286C2.97656 1.97619 2.8125 2.38095 2.8125 2.85714V4Z" fill="white" />
          </svg>
          Түгжих
        </Link>
        <Link to={`/sell/${symbol}`} className="rounded-[4px] font-semibold text-[14px] bg-[rgba(0,0,0,.16)] px-[13px] py-[10px] flex-1 text-center">
          <svg className="inline mr-[10px] mb-[4px]" width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 4H6.875V2.85714C6.875 2.38095 6.69271 1.97619 6.32812 1.64286C5.96354 1.30952 5.52083 1.14286 5 1.14286C4.47917 1.14286 4.03646 1.30952 3.67188 1.64286C3.30729 1.97619 3.125 2.38095 3.125 2.85714H1.875C1.875 2.06667 2.17979 1.39276 2.78938 0.835429C3.39896 0.278096 4.13583 -0.000380562 5 3.9032e-07C5.86458 3.9032e-07 6.60167 0.278667 7.21125 0.836C7.82083 1.39333 8.12542 2.06705 8.125 2.85714V4H8.75C9.09375 4 9.38812 4.112 9.63312 4.336C9.87812 4.56 10.0004 4.82895 10 5.14286V10.8571C10 11.1714 9.8775 11.4406 9.6325 11.6646C9.3875 11.8886 9.09333 12.0004 8.75 12H1.25C0.906251 12 0.611876 11.888 0.366876 11.664C0.121876 11.44 -0.000415605 11.171 1.06112e-06 10.8571V5.14286C1.06112e-06 4.82857 0.122501 4.55943 0.367501 4.33543C0.612501 4.11143 0.906668 3.99962 1.25 4ZM1.25 10.8571H8.75V5.14286H1.25V10.8571ZM5 9.14286C5.34375 9.14286 5.63813 9.03086 5.88313 8.80686C6.12813 8.58286 6.25042 8.3139 6.25 8C6.25 7.68571 6.1275 7.41657 5.8825 7.19257C5.6375 6.96857 5.34333 6.85676 5 6.85714C4.65625 6.85714 4.36188 6.96914 4.11688 7.19314C3.87188 7.41714 3.74958 7.6861 3.75 8C3.75 8.31429 3.8725 8.58343 4.1175 8.80743C4.3625 9.03143 4.65667 9.14324 5 9.14286Z" fill="white" />
          </svg>
          Суллах
        </Link>
      </div>
    </div>
  </>;
};


export default Balance;
