import { Link } from "react-router-dom";
import assets from "../assets";

const Admin = () => {
  return (
    <>
      <div className="px-4 container m-auto mb-[100px] max-w-[1140px]">
        {Object.keys(assets).map((asset) => <Link className="block" key={asset} to={`/execution/${asset}`}>{asset}</Link>)}
        <br />
        <Link to="/prices">USDT авах/зарах ханш өөрчлөх</Link><br />
        <br />
        <Link to="/fiatdepositrequests">Fiat Deposit Requests</Link><br />
        <Link to="/fiatwithdrawrequests">Fiat Withdraw Requests</Link><br />
        <br />
        <Link to="/users">Users</Link>
      </div>
    </>
  );
}

export default Admin;
