import { Link } from "react-router-dom";

const Withdraw = () => {
  return (
    <>
      <div className="px-4 container m-auto mb-[100px] max-w-[1140px]">
        <div className="text-[#E6E7EB] text-[24px] font-bold mb-[24px]">Зарлага</div>

        <div className="flex flex-col sm:flex-row gap-[16px]">
          <Link className="flex-1 text-center rounded text-[14px] font-bold bg-gradient-to-t from-[#006CFF] to-[#13A9FD] px-[24px] py-[17px]" to="/cryptowithdraw">USDT зарлага</Link>
          <Link className="flex-1 text-center rounded text-[14px] font-bold bg-gradient-to-t from-[#006CFF] to-[#13A9FD] px-[24px] py-[17px]" to="/fiatwithdraw">MNT зарлага</Link><br />
        </div>
      </div>
    </>
  );
}

export default Withdraw;
