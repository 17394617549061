import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="py-8 flex-1 mt-[100px]">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
