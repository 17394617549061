import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="w-full bg-[#040C2B]">
        <div className="px-4 container m-auto py-[17px] flex justify-between text-[#B4B7C3] text-[12px] items-center max-w-[1140px]">
          <p className="">Copyright &copy; 2023. Чойжин Алтекс ХХК. Зохиогчийн эрх хуулиар хамгаалагдсан.</p>
          <p>
            <Link to="/tos">Үйлчилгээний нөхцөл</Link>
            {/* <Link to="/tos" className="border-r-[1px] border-[#3B4466] pr-[24px] mr-[24px]">Үйлчилгээний нөхцөл</Link>
             */}{/* <a href="#_">Privacy Policy</a> */}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
