import Balance from "./Balance";

const Profile = () => {
  return (
    <>
      <Balance />
    </>
  );
}

export default Profile;
