import {
  useNavigate,
  Routes,
  Route,
} from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import './index.css';
import Layout from "./components/Layout";
import { createContext, useEffect } from "react";
import Home from "./components/Home";
import Auth from "./components/Auth";
import Profile from "./components/Profile";
import Withdraw from "./components/Withdraw";
import Deposit from "./components/Deposit";
import Sell from "./components/Sell";
import Users from "./components/Users";
import Buy from "./components/Buy";
import Execution from "./components/Execution";
import TermsOfService from "./components/TermsOfService";
import Admin from "./components/Admin";
import CryptoDeposit from "./components/CryptoDeposit";
import FiatDeposit from "./components/FiatDeposit";
import CryptoWithdraw from "./components/CryptoWithdraw";
import FiatWithdraw from "./components/FiatWithdraw";
import FiatDepositRequests from "./components/FiatDepositRequests";
import FiatWithdrawRequests from "./components/FiatWithdrawRequests";
import Prices from "./components/Prices";

export const Context = createContext();

const App = () => {
  return (
    <>
      <div className="font-[Montserrat] bg-[#050F36] text-[#E6E7EB] overflow-auto">
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/profile" element={<RequireAuth><Profile /></RequireAuth>} />
            <Route path="/deposit" element={<RequireAuth><Deposit /></RequireAuth>} />
            <Route path="/cryptodeposit" element={<RequireAuth><CryptoDeposit /></RequireAuth>} />
            <Route path="/fiatdeposit" element={<RequireAuth><FiatDeposit /></RequireAuth>} />
            <Route path="/withdraw" element={<RequireAuth><Withdraw /></RequireAuth>} />
            <Route path="/cryptowithdraw" element={<RequireAuth><CryptoWithdraw /></RequireAuth>} />
            <Route path="/fiatwithdraw" element={<RequireAuth><FiatWithdraw /></RequireAuth>} />
            <Route path="/buy/:symbol" element={<RequireAuth><Buy /></RequireAuth>} />
            <Route path="/sell/:symbol" element={<RequireAuth><Sell /></RequireAuth>} />
            <Route path="/users" element={<RequireAuth><Users /></RequireAuth>} />
            <Route path="/admin" element={<RequireAuth><Admin /></RequireAuth>} />
            <Route path="/execution/:symbol" element={<RequireAuth><Execution /></RequireAuth>} />
            <Route path="/fiatdepositrequests" element={<RequireAuth><FiatDepositRequests /></RequireAuth>} />
            <Route path="/fiatwithdrawrequests" element={<RequireAuth><FiatWithdrawRequests /></RequireAuth>} />
            <Route path="/prices" element={<RequireAuth><Prices /></RequireAuth>} />
            <Route path="/tos" element={<TermsOfService />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;

function RequireAuth({ children }) {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) navigate("/auth");
  }, [user, loading, navigate]);

  return user && children;
}