import { Link, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { useEffect, useState } from "react";

const Navbar = () => {
  // 2023/04/20 16:20 +08:00
  const launch = 1681978800000;
  const [user] = useAuthState(auth);
  const [hidden, setHidden] = useState(true);
  const [navCollapsed, setNavCollapsed] = useState(true);
  const [timeLeft, setTimeLeft] = useState(launch - Date.now());
  const location = useLocation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(launch - Date.now());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <nav className="fixed w-screen bg-[#040C2B] z-20">
      <div className="px-4 container mx-auto max-w-[1140px]">
        <div className="flex justify-between">
          <div className="flex space-x-4 py-[24px]">
            <Link to="/" className="flex items-center">
              <svg width="235" height="40" viewBox="0 0 235 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9992 38.1766L13.127 38.2507L13.2481 38.3179L16.1615 40V36.6426V36.5013V36.3533V36.212V32.8545V32.7065V32.5652V32.4239V29.0665V28.9184V28.7771V28.6359V25.2717L13.2481 26.9538L13.127 27.0278V27.1691V30.5332V30.6745V30.8158V30.9571V34.3213V34.4626V34.6039V34.7452L12.9992 34.6779L12.878 34.6039L12.7569 34.5299L9.84357 32.8545L9.72246 32.7805L9.59462 32.7065L9.47351 32.6392L6.56014 30.9571L6.43902 30.8898L6.31792 30.8158L6.19008 30.7418L3.28343 29.0665L3.15559 28.9925L3.03448 28.9184V28.7771V28.6359V25.2784V25.1304V24.9891V24.8478V21.4836V21.3423V21.201V21.0597V17.6956V17.5543V17.413V17.2717L3.15559 17.339L3.28343 17.413L3.40454 17.487L6.31792 19.1623L6.43902 19.2364L6.56014 19.3036L6.68797 19.3777L9.59462 21.0597L9.72246 21.127L9.84357 21.0597L12.7569 19.3777L9.84357 17.6956L9.72246 17.6283L9.59462 17.5543L9.47351 17.487L6.56014 15.8049L6.43902 15.7309L6.31792 15.6636L6.19008 15.5896L3.28343 13.9075L3.15559 13.8402L3.03448 13.7662L2.91337 13.6922L0 12.0168V15.3743V15.5156V15.6636V15.8049V19.1623V19.3036V19.4517V19.593V22.9504V23.0984V23.2397V23.381V26.7385V26.8865V27.0278V27.1691V30.5332V30.6745L0.12111 30.7418L3.03448 32.4239L3.15559 32.4979L3.28343 32.5652L3.40454 32.6392L6.31792 34.3213L6.43902 34.3886L6.56014 34.4626L6.68797 34.5299L9.59462 36.212L9.72246 36.286L9.84357 36.3533L9.96468 36.4273L12.878 38.1094L12.9992 38.1766Z" fill="#006CFF" />
                <path d="M4.64251 11.0816L4.76362 11.1489L4.88474 11.2229L7.79811 12.905L7.91922 12.9722L8.04706 13.0463L8.16817 13.1203L11.0815 14.7956L11.2026 14.8696L11.3238 14.9437L11.4516 15.0109L14.3582 16.693V13.3288V13.1876L14.2371 13.1203L11.3238 11.4382L11.2026 11.3642L11.0815 11.2969L10.9537 11.2229L8.04706 9.54079L7.91922 9.47351L7.79811 9.3995L7.677 9.33221L7.79811 9.2582L7.91922 9.18419L8.04706 9.11691L10.9537 7.43482L11.0815 7.36081L11.2026 7.29352L11.3238 7.21951L14.2371 5.53743L14.3582 5.47014L14.4861 5.39613L14.6072 5.32885L17.5206 3.64676L17.6417 3.57275L17.7628 3.50547L17.8906 3.57275L18.0117 3.64676L20.9251 5.32885L21.0462 5.39613L21.1673 5.47014L21.2952 5.53743L24.2018 7.21951L24.3296 7.29352L24.4508 7.36081L24.5719 7.43482L27.4852 9.11691L27.6063 9.18419L27.7342 9.2582L27.8553 9.33221L27.7342 9.3995L27.6063 9.47351L27.4852 9.54079L24.5719 11.2229L24.4508 11.2969L24.3296 11.3642L24.2018 11.4382L21.2952 13.1203L21.1673 13.1876V13.3288V16.693L24.0807 15.0109L24.2018 14.9437L24.3296 14.8696L24.4508 14.7956L27.3641 13.1203L27.4852 13.0463L27.6063 12.9722L27.7342 12.905L30.6408 11.2229L30.7687 11.1489L30.8898 11.0816L31.0109 11.0076L33.9243 9.33221L31.0109 7.65013L30.8898 7.57611L30.7687 7.50883L30.6408 7.43482L27.7342 5.75273L27.6063 5.68545L27.4852 5.61144L27.3641 5.53743L24.4508 3.86207L24.3296 3.78806L24.2018 3.71405L24.0807 3.64676L21.1673 1.96468L21.0462 1.89739L20.9251 1.82338L20.7973 1.74937L17.8906 0.0740118L17.7628 0L17.6417 0.0740118L14.7283 1.74937L14.6072 1.82338L14.4861 1.89739L14.3582 1.96468L11.4516 3.64676L11.3238 3.71405L11.2026 3.78806L11.0815 3.86207L8.16817 5.53743L8.04706 5.61144L7.91922 5.68545L7.79811 5.75273L4.88474 7.43482L4.76362 7.50883L4.64251 7.57611L4.51468 7.65013L1.60803 9.33221L4.51468 11.0076L4.64251 11.0816Z" fill="#006CFF" />
                <path d="M32.498 13.7662L32.3702 13.8402L32.2491 13.9075L29.3357 15.5896L29.2146 15.6636L29.0935 15.7309L28.9656 15.8049L26.059 17.487L25.9311 17.5543L25.81 17.6283L25.6889 17.6956L22.7755 19.3777L25.6889 21.0597L25.81 21.127L25.9311 21.0597L28.8445 19.3777L28.9656 19.3036L29.0935 19.2364L29.2146 19.1623L32.1279 17.487L32.2491 17.413L32.3702 17.339L32.498 17.2717V17.413V17.5543V17.6956V21.0597V21.201V21.3423V21.4836V24.8478V24.9891V25.1304V25.2717V28.6359V28.7771V28.9184L32.3702 28.9925L32.2491 29.0665L29.3357 30.7418L29.2146 30.8158L29.0935 30.8898L28.9656 30.9571L26.059 32.6392L25.9311 32.7065L25.81 32.7805L25.6889 32.8545L22.7755 34.5299L22.6544 34.6039L22.5266 34.6779L22.4055 34.7452V34.6039V34.4626V34.3213V30.9571V30.8158V30.6745V30.5332V27.1691V27.0278L22.2844 26.9538L19.371 25.2784V28.6359V28.7771V28.9184V29.0665V32.4239V32.5652V32.7065V32.8545V36.212V36.3533V36.5013V36.6426V40L22.2844 38.3179L22.4055 38.2507L22.5266 38.1766L22.6544 38.1094L25.5611 36.4273L25.6889 36.3533L25.81 36.286L25.9311 36.212L28.8445 34.5299L28.9656 34.4626L29.0935 34.3886L29.2146 34.3213L32.1279 32.6392L32.2491 32.5652L32.3702 32.4979L32.498 32.4239L35.4046 30.7418L35.5325 30.6745V30.5265V27.1691V27.0278V26.8865V26.7385V23.381V23.2397V23.0984V22.9504V19.593V19.4517V19.3036V19.1623V15.8049V15.6636V15.5156V15.3743V12.0168L32.6191 13.6922L32.498 13.7662Z" fill="#006CFF" />
                <path d="M100.077 14.7216H91.8214V17.2447H100.077V14.7216Z" fill="white" />
                <path d="M76.5011 17.3793H79.6971V25.3725H82.4557V17.3793H85.6516V14.6274H76.5011V17.3793Z" fill="white" />
                <path d="M94.5733 22.6207V21.3759V21.3086H100.171V18.624H91.8214V25.3726H100.171V22.6879H94.5733V22.6207Z" fill="white" />
                <path d="M105.124 25.3726H108.239L109.423 23.5694L107.896 21.3087L105.124 25.3726Z" fill="white" />
                <path d="M110.284 17.7022L110.096 17.4398L108.198 14.6274H105.144L112.457 25.3725H115.485L110.284 17.7022Z" fill="white" />
                <path d="M115.31 14.7216H112.437L111.294 16.3902L112.754 18.5366L115.31 14.7216Z" fill="white" />
                <path d="M50.9804 14.6274L46.7819 25.3725H49.7289L52.5616 17.7022L54.3446 22.5601H52.4876L51.4648 25.3725H58.3345L54.136 14.6274H50.9804Z" fill="white" />
                <path d="M71.0447 22.6274H70.1499V22.6207H66.9539V14.6274H64.202V25.3725H69.6721H70.1499H71.9665L71.0447 22.6274Z" fill="white" />
                <path d="M126.675 25.2717V14.688H128.188V25.2717H126.675Z" fill="#006CFF" />
                <path d="M132.044 25.2717V14.688H133.282L140.286 23.381H139.634V14.688H141.147V25.2717H139.909L132.905 16.5787H133.558V25.2717H132.044Z" fill="#006CFF" />
                <path d="M148.219 25.2717L143.563 14.688H145.198L149.477 24.4575H148.542L152.854 14.688H154.368L149.726 25.2717H148.232H148.219Z" fill="#006CFF" />
                <path d="M158.298 23.9529H164.468V25.2717H156.784V14.688H164.252V16.0067H158.298V23.9596V23.9529ZM158.163 19.2498H163.606V20.5349H158.163V19.2498Z" fill="#006CFF" />
                <path d="M170.9 25.3928C170.093 25.3928 169.326 25.2717 168.585 25.0227C167.845 24.7738 167.267 24.4575 166.85 24.0606L167.408 22.8831C167.812 23.233 168.323 23.529 168.949 23.7578C169.575 23.9865 170.227 24.1077 170.9 24.1077C171.512 24.1077 172.017 24.0404 172.394 23.8991C172.771 23.7578 173.06 23.5627 173.242 23.3137C173.423 23.0648 173.511 22.7889 173.511 22.4727C173.511 22.1093 173.39 21.82 173.154 21.598C172.919 21.3759 172.609 21.1943 172.232 21.0597C171.855 20.9251 171.438 20.804 170.988 20.7031C170.537 20.6022 170.079 20.4811 169.622 20.3465C169.164 20.2119 168.747 20.037 168.363 19.8217C167.987 19.6131 167.684 19.3238 167.448 18.9672C167.213 18.6106 167.099 18.1463 167.099 17.5812C167.099 17.016 167.24 16.5383 167.529 16.074C167.818 15.6165 168.263 15.2464 168.861 14.9706C169.46 14.6947 170.227 14.5534 171.149 14.5534C171.761 14.5534 172.374 14.6341 172.979 14.7956C173.585 14.9571 174.109 15.1859 174.554 15.4886L174.056 16.6997C173.605 16.397 173.12 16.1749 172.622 16.0404C172.125 15.9058 171.627 15.8385 171.156 15.8385C170.564 15.8385 170.072 15.9125 169.689 16.0673C169.305 16.222 169.023 16.4172 168.848 16.6728C168.673 16.9218 168.585 17.2044 168.585 17.5206C168.585 17.8907 168.707 18.1934 168.942 18.4155C169.178 18.6375 169.487 18.8124 169.864 18.947C170.241 19.0749 170.658 19.196 171.109 19.3036C171.559 19.4113 172.017 19.5257 172.474 19.6602C172.932 19.7881 173.349 19.963 173.733 20.1716C174.109 20.3802 174.412 20.6627 174.648 21.0193C174.883 21.3759 174.998 21.8267 174.998 22.3785C174.998 22.9302 174.85 23.4079 174.56 23.8654C174.271 24.323 173.813 24.693 173.208 24.9689C172.602 25.2447 171.829 25.386 170.9 25.386V25.3928Z" fill="#006CFF" />
                <path d="M180.38 25.2717V16H176.754V14.6813H185.507V16H181.881V25.2717H180.387H180.38Z" fill="#006CFF" />
                <path d="M187.963 25.2717V14.688H189.201L193.951 22.688H193.285L197.974 14.688H199.212L199.226 25.2717H197.773L197.759 16.9689H198.109L193.937 23.9866H193.244L189.039 16.9689H189.416V25.2717H187.963Z" fill="#006CFF" />
                <path d="M204.589 23.9529H210.759V25.2717H203.075V14.688H210.543V16.0067H204.589V23.9596V23.9529ZM204.454 19.2498H209.897V20.5349H204.454V19.2498Z" fill="#006CFF" />
                <path d="M213.739 25.2717V14.688H214.977L221.982 23.381H221.329V14.688H222.843V25.2717H221.605L214.601 16.5787H215.253V25.2717H213.739Z" fill="#006CFF" />
                <path d="M228.925 25.2717V16H225.299V14.6813H234.052V16H230.426V25.2717H228.932H228.925Z" fill="#006CFF" />
              </svg>
            </Link>
          </div>
          <div className="hidden md:flex space-x-4 items-center">
            {user ? <>
              <Link to="/profile" className={`h-full text-[14px] flex items-center justify-center p-[20px] ${location.pathname === "/profile" && "bg-[#050F36]"}`}>
                <svg className="inline mr-[10px] mt-[-5px]" width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0521 11.4392H6.66158C6.46094 11.4392 6.29846 11.6017 6.29846 11.8023V17.0665C6.29846 17.2671 6.46094 17.4296 6.66158 17.4296H10.0521C10.2527 17.4296 10.4149 17.2671 10.4149 17.0665V11.8021C10.4149 11.6014 10.2525 11.4392 10.0521 11.4392ZM15.3385 0.570801H11.9481C11.7474 0.570801 11.5849 0.733281 11.5849 0.933921V17.0662C11.5849 17.2669 11.7474 17.4294 11.9481 17.4294H15.3385C15.5392 17.4294 15.7017 17.2669 15.7017 17.0662V0.933921C15.7017 0.733281 15.5392 0.570801 15.3385 0.570801ZM20.6521 5.47184H17.2617C17.061 5.47184 16.8985 5.63432 16.8985 5.83496V17.0662C16.8985 17.2669 17.061 17.4294 17.2617 17.4294H20.6519C20.8525 17.4294 21.015 17.2669 21.015 17.0662V5.83496C21.015 5.63456 20.8525 5.47184 20.6521 5.47184ZM4.73846 5.47184H1.34798C1.14734 5.47184 0.984863 5.63432 0.984863 5.83496V17.0662C0.984863 17.2669 1.14734 17.4294 1.34798 17.4294H4.73846C4.9391 17.4294 5.10158 17.2669 5.10158 17.0662V5.83496C5.10158 5.63456 4.9391 5.47184 4.73846 5.47184Z" fill="#E6E7EB" />
                </svg>
                Портфолио
              </Link>
              <div onClick={() => setNavCollapsed(!navCollapsed)}className="block py-5 px-2 text-[14px] cursor-pointer">
                <svg className="inline mr-[10px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 17.2C7.5 17.2 5.29 15.92 4 14C4.03 12 8 10.9 10 10.9C12 10.9 15.97 12 16 14C15.3389 14.9844 14.4459 15.7912 13.3996 16.3492C12.3533 16.9072 11.1858 17.1994 10 17.2ZM10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6C13 6.79565 12.6839 7.55871 12.1213 8.12132C11.5587 8.68393 10.7956 9 10 9C9.20435 9 8.44129 8.68393 7.87868 8.12132C7.31607 7.55871 7 6.79565 7 6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0Z" fill="#E6E7EB" />
                </svg>
                <svg className={`inline ${navCollapsed && "rotate-180"}`} width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 5L5 -4.37114e-07L0 5L10 5Z" fill="#E6E7EB" />
                </svg>
                <div className={`${navCollapsed && "hidden"} absolute w-[270px] bg-[#040C2B] top-full right-0 flex flex-col p-[18px] gap-[18px]`}>
                  <Link to="/profile">
                    <svg className="inline mr-[10px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 17.2C7.5 17.2 5.29 15.92 4 14C4.03 12 8 10.9 10 10.9C12 10.9 15.97 12 16 14C15.3389 14.9844 14.4459 15.7912 13.3996 16.3492C12.3533 16.9072 11.1858 17.1994 10 17.2ZM10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6C13 6.79565 12.6839 7.55871 12.1213 8.12132C11.5587 8.68393 10.7956 9 10 9C9.20435 9 8.44129 8.68393 7.87868 8.12132C7.31607 7.55871 7 6.79565 7 6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0Z" fill="#B4B7C3" />
                    </svg>
                    {user.email}
                  </Link>
                  <div className="cursor-pointer" onClick={() => auth.signOut()}>
                    <svg className="inline mr-[10px]" width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8673 0.000249421C14.4271 0.000249421 15.7128 1.28294 15.7128 2.86852V5.03266C15.712 5.12632 15.6927 5.21899 15.656 5.30518C15.6193 5.39136 15.5658 5.46941 15.4987 5.53501C15.4316 5.60044 15.3523 5.65192 15.2652 5.68662C15.1782 5.72133 15.0851 5.73827 14.9914 5.73677L13.5772 5.7396C13.1615 5.7396 12.856 5.41544 12.856 5.0154V3.22273C12.856 3.02428 12.7017 2.87001 12.5117 2.87001H5.71396L10.1591 5.09694C10.859 5.44684 11.4275 6.36666 11.4275 7.15097V13.9602C11.4258 14.0082 11.4338 14.0562 11.451 14.1012C11.4685 14.1462 11.4949 14.187 11.5284 14.2216C11.5621 14.256 11.6025 14.2832 11.647 14.3016C11.6915 14.3201 11.7393 14.3292 11.7875 14.3287H12.4959C12.5428 14.33 12.5893 14.3219 12.6329 14.3049C12.6766 14.288 12.7163 14.2624 12.7498 14.2297C12.7834 14.1972 12.8101 14.1581 12.8284 14.115C12.8465 14.0718 12.856 14.0255 12.856 13.9786V12.1889C12.8545 12.0938 12.8722 11.9993 12.9081 11.9113C12.9438 11.8231 12.9969 11.7431 13.0642 11.6758C13.1315 11.6085 13.2115 11.5554 13.2997 11.5197C13.3877 11.4838 13.4822 11.4662 13.5772 11.4676H14.9914C15.0854 11.4646 15.1789 11.4807 15.2666 11.5147C15.3541 11.5488 15.434 11.6002 15.5012 11.6658C15.5686 11.7314 15.6219 11.8098 15.6583 11.8965C15.6947 11.9832 15.7131 12.0763 15.7128 12.1703V14.3315C15.7128 15.9227 14.4329 17.1941 12.8545 17.1941H11.4275V17.9212C11.4275 19.5068 10.2148 20.3651 8.71918 19.851L2.70834 17.7868C1.22274 17.2768 0 15.5757 0 13.9972V2.85981C0 1.26708 1.27423 0 2.84553 0L12.8673 0.000249421Z" fill="#B4B7C3" />
                      <path d="M19.0281 4.11124C18.7766 3.81698 18.5696 3.89851 18.5696 4.2984V7.14673H13.213C13.1195 7.14673 13.0298 7.18326 12.9629 7.24869C12.8961 7.31412 12.8578 7.40313 12.856 7.49663V9.64933C12.8561 9.69583 12.8654 9.74183 12.8835 9.78467C12.9016 9.82752 12.9282 9.86638 12.9614 9.89892C12.9946 9.93147 13.034 9.95705 13.0772 9.97432C13.1203 9.99159 13.1665 10.0001 13.213 9.99923L18.5696 10.0035V12.8532C18.5696 13.2446 18.7753 13.3346 19.0281 13.0389L22.6248 8.84373C22.6858 8.76734 22.719 8.67268 22.719 8.57503C22.719 8.47755 22.6858 8.38273 22.6248 8.30651L19.0281 4.11124Z" fill="#B4B7C3" />
                    </svg>
                    Гарах
                  </div>
                </div>
              </div>
            </> : <>
              {timeLeft <= 0 && <>
                <Link to="/auth" className="rounded-[4px] py-[12px] px-[30px] font-[700] text-[#13A9FD] border-[1px] border-[#13A9FD]">Нэвтрэх</Link>
              </>}
            </>}
          </div>
          <div className="md:hidden flex items-center">
            <button id="mobile-menu-button" className="py-2 px-4" onClick={() => setHidden(!hidden)}>
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div id="mobile-menu" className={`${hidden && "hidden"} container mx-auto md:hidden px-[8px] max-w-[1140px]`}>
        {user ? <>
          <Link to="/profile" className="block py-2 px-4 text-sm text-gray-500 hover:text-gray-700">Портфолио</Link>
          <Link to="/profile" className="block py-2 px-4 text-sm text-gray-500 hover:text-gray-700">{user.email}</Link>
          <div className="block py-2 px-4 text-sm text-gray-500 hover:text-gray-700 cursor-pointer" onClick={() => auth.signOut()}>Гарах</div>
        </> : <>
          {timeLeft <= 0 && <>
            <Link to="/auth" className="block py-2 px-4 text-sm text-[#13A9FD] border-[1px] border-[#13A9FD]">Нэвтрэх</Link>
          </>}
        </>}
      </div>
    </nav>
  );
}

export default Navbar;
