import { getFunctions, httpsCallable } from "firebase/functions";

const Request = ({ request, getRequests }) => {
  const functions = getFunctions();

  const remove = () => {
    if (window.confirm("Та хүсэлтийг устгахдаа итгэлтэй байна уу?")) {
      const addMessage = httpsCallable(functions, "deleteRequest");
      addMessage({
        id: request.id,
      }).then(() => {
        getRequests();
      });
    }
  }

  return (
    <>
      <div key={request.id} className={`rounded-[8px] mb-[10px] px-[15px] py-[10px] bg-gradient-to-tl to-[rgba(16,28,71,0)] ${request.type === "buy" ? "from-[rgba(10,193,142,.25)]" : "from-[rgba(255,60,43,.25)]"}`}>
        <span className="text-[12px] opacity-50">{(new Date(request.timestamp)).toLocaleString()}</span>
        <div className="float-right opacity-50 text-[12px] ml-[5px]">
          {request.status === "pending" && "хүлээгдэж буй"}
          {request.status === "frozen" && "биелэж буй"}
          {request.status === "finalized" && "биелэгдсэн"}
          {request.status === "pending" && <span className="inline-block cursor-pointer text-[15px] scale-y-90 ml-[5px]" onClick={remove}>x</span>}
        </div>
        <div>
          <div className="inline mr-[5px]">
            {request.type === "buy" ? <><strong>BUY</strong> {request.symbol} for</> : <><strong>SELL</strong></>}
          </div>
          <span className="font-bold mr-[5px]">
            {Math.floor(request.amount * 10**2) / 10**2}
          </span>
          {request.type === "buy" ? <>USDT</> : request.symbol}
        </div>
      </div>
    </>
  )
}

export default Request;
