import { writeBatch, doc, query, getDocs, collection, where } from "firebase/firestore"; 
import { db } from '../firebase';
import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";

const Execution = () => {
  const [requestsFrozen, setRequestsFrozen] = useState([]);
  const [requestsPending, setRequestsPending] = useState([]);
  const [totalBuyFrozen, setTotalBuyFrozen] = useState(0);
  const [totalSellFrozen, setTotalSellFrozen] = useState(0);
  const [totalBuyPending, setTotalBuyPending] = useState(0);
  const [totalSellPending, setTotalSellPending] = useState(0);
  const [buy, setBuy] = useState(0);
  const [sell, setSell] = useState(0);
  const [executeSellPrice, setExecuteSellPrice] = useState("");
  const [executeBuyPrice, setExecuteBuyPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const {symbol} = useParams();

  const getRequests = async () => {
    setLoading(true);

    const q = query(collection(db, "requests"), where("status", "!=", "executed"), where("symbol", "==", symbol));
    getDocs(q).then((screenshot) => {
      const rqs = screenshot.docs.map((request) => ({ id: request.id, ...request.data() }));
      const rqsfrozen = rqs.filter((rq) => rq.status === "frozen");
      const rqspending = rqs.filter((rq) => rq.status === "pending");

      setRequestsFrozen(rqsfrozen);
      setRequestsPending(rqspending);

      setLoading(false);

      const tbuyfrozen = rqsfrozen.filter((r) => r.type === "buy").reduce((acc, cur) => acc + parseFloat(cur.amount), 0);
      const tsellfrozen = rqsfrozen.filter((r) => r.type === "sell").reduce((acc, cur) => acc + parseFloat(cur.amount), 0);
      const tbuypending = rqspending.filter((r) => r.type === "buy").reduce((acc, cur) => acc + parseFloat(cur.amount), 0);
      const tsellpending = rqspending.filter((r) => r.type === "sell").reduce((acc, cur) => acc + parseFloat(cur.amount), 0);

      setTotalBuyFrozen(tbuyfrozen);
      setTotalSellFrozen(tsellfrozen);
      setTotalBuyPending(tbuypending);
      setTotalSellPending(tsellpending);
    });
  }

  useEffect(() => {
    getRequests();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (executeBuyPrice && executeSellPrice) {
      const b = totalSellFrozen - totalBuyFrozen / executeBuyPrice;
      const s = totalBuyFrozen / executeSellPrice - totalSellFrozen

      setBuy(b);
      setSell(s);
    }
  }, [executeBuyPrice, executeSellPrice, totalBuyFrozen, totalSellFrozen]);

  const freeze = async () => {
    setLoading(true);
    let rs = [...requestsPending];
    let splice;

    do {
      splice = rs.splice(0, 500);

      setRequestsPending(rs);

      const batch = writeBatch(db);

      // Get a new write batch
      splice.forEach((row) => {
        const docRef = doc(db, "requests", row.id);
        row.status = "frozen";
        batch.set(docRef, row);
      });

      // Commit the batch
      await batch.commit();
      getRequests();
    } while (rs.length);

    setLoading(false);
  }

  const execute = async () => {
    if (executeBuyPrice > 0 || executeSellPrice > 0) {
      setLoading(true);
      let rs = [...requestsFrozen];
      let splice;

      do {
        splice = rs.splice(0, 500);

        setRequestsFrozen(rs);

        const batch = writeBatch(db);

        // Get a new write batch
        splice.forEach((row) => {
          const docRef = doc(db, "requests", row.id);
          row.status = "executed";
          row.executedAt = Date.now();
          row.price = row.type === "buy" ? executeBuyPrice : executeSellPrice;
          batch.set(docRef, row);
        });

        // Commit the batch
        await batch.commit();
        getRequests();
      } while (rs.length);

      setLoading(false);
    } else {
      alert("Please enter the price first");
    }
  }

  return (
    <>
      <div className="px-4 container m-auto max-w-[1140px]">
        <Link className="block" to="/admin">Admin</Link>
        {loading && <>
          <svg className="inline animate-spin mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
            <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
          </svg>
        </>}

        {requestsFrozen.length > 0 && <>
          <h1 className="text-[#E6E7EB] text-[24px] font-bold mb-[15px]">Frozen</h1>

          {requestsFrozen.map((request) => <Request request={request} key={request.id} />)}

          <div className="flex my-[15px]">
            <div className="flex-1">
              <div>Total Buy</div>
              <div className="inline text-[25px] font-bold">
                {totalBuyFrozen.toFixed(2)}
              </div>
              <span className="mx-[5px]">USDT</span>
              {executeBuyPrice > 0 && executeSellPrice > 0 && <>
                (<strong>{(totalBuyFrozen / executeBuyPrice).toFixed(2)}</strong> {symbol})
              </>}
            </div>

            <div className="flex-1">
              <div>Total Sell</div>
              <div className="inline text-[25px] font-bold">
                {totalSellFrozen.toFixed(2)}
              </div>
              <span className="mx-[5px]">{symbol}</span>
              {executeBuyPrice > 0 && executeSellPrice > 0 && <>
                (<strong>{(totalSellFrozen * executeSellPrice).toFixed(2)}</strong> USDT)
              </>}
            </div>
          </div>

          <div className="flex flex-col md:flex-row my-[15px] gap-[15px]">
            <div className="flex-1">
              <label htmlFor="buyprice" className="block text-[#E6E7EB] mb-[10px]">Buy {symbol} price</label>
              <div className="rounded border border-[#E6E7EB] w-full flex">
                <input id="buyprice" placeholder="0.00" type="number" value={executeBuyPrice} onChange={(e) => setExecuteBuyPrice(e.target.value)} className="px-[16px] py-[14px] bg-transparent flex-grow" required />
                <div className="border-l border-l-[#82879B] text-[#82879B] px-[16px] h-[32px] m-auto pt-[4px]">USDT</div>
              </div>
            </div>

            <div className="flex-1">
              <label htmlFor="sellprice" className="block text-[#E6E7EB] mb-[10px]">Sell {symbol} price</label>
              <div className="rounded border border-[#E6E7EB] w-full flex">
                <input id="sellprice" placeholder="0.00" type="number" value={executeSellPrice} onChange={(e) => setExecuteSellPrice(e.target.value)} className="px-[16px] py-[14px] bg-transparent flex-grow" required />
                <div className="border-l border-l-[#82879B] text-[#82879B] px-[16px] h-[32px] m-auto pt-[4px]">USDT</div>
              </div>
            </div>
          </div>

          {executeBuyPrice > 0 && executeSellPrice > 0 && <>
            <div className="flex my-[15px] gap-[15px]">
              <div className="flex-1">
                <div>Buy</div>
                <div className="inline text-[30px] font-bold">
                  {buy.toFixed(2)}
                </div>
                <span className="mx-[5px]">{symbol}</span>
              </div>
              <div className="flex-1">
                <div>Sell</div>
                <div className="inline text-[30px] font-bold">
                  {sell.toFixed(2)}
                </div>
                <span className="mx-[5px]">{symbol}</span>
              </div>
            </div>
          </>}

          <button onClick={execute} className="block rounded text-[14px] font-bold bg-gradient-to-t from-[#006CFF] to-[#13A9FD] px-[24px] py-[17px] w-full mb-[100px]" disabled={loading}>
            {loading && <>
              <svg className="inline animate-spin mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
              </svg>
            </>}
            Execute
          </button>
        </>}

        {requestsPending.length > 0 && <>
          <h1 className="text-[#E6E7EB] text-[24px] font-bold mb-[15px]">Pending</h1>

          {requestsPending.map((request) => <Request request={request} key={request.id} />)}

          <div className="flex my-[15px]">
            <div className="flex-1">
              <div>Total Buy</div>
              <div className="inline text-[25px] font-bold">
                {totalBuyPending.toFixed(2)}
              </div>
              <span className="mx-[5px]">USDT</span>
            </div>

            <div className="flex-1">
              <div>Total Sell</div>
              <div className="inline text-[25px] font-bold">
                {totalSellPending.toFixed(2)}
              </div>
              <span className="mx-[5px]">{symbol}</span>
            </div>
          </div>

          <button onClick={freeze} className="block rounded text-[14px] font-bold bg-gradient-to-t from-[#006CFF] to-[#13A9FD] px-[24px] py-[17px] w-full mb-[100px]" disabled={loading}>
            {loading && <>
              <svg className="inline animate-spin mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#71CBFE" />
                <path d="M20 12H22C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z" fill="#13A9FD" />
              </svg>
            </>}
            Freeze
          </button>
        </>}
      </div>
    </>
  );
}

const Request = ({request}) => {
  return (
    <>
      <div key={request.id} className={`rounded-[8px] mb-[10px] px-[15px] py-[10px] bg-gradient-to-r to-[rgba(16,28,71,0)] ${request.type === "buy" ? "from-[rgba(19,169,253,.25)]" : "from-[rgba(254,103,3,.25)]"}`}>
        <div className="inline mr-[5px]">
          {request.type === "buy" ? <><strong>BUY</strong> {request.symbol} for</> : <><strong>SELL</strong></>}
        </div>
        <span className="font-bold mr-[5px]">
          {request.amount}
        </span>
        {request.type === "buy" ? <>USDT</> : request.symbol}
        <span className="text-[12px] opacity-50 ml-[5px]">{(new Date(request.timestamp)).toLocaleString()}</span>
        <span className="text-[12px] opacity-50 ml-[5px]">{request.user.id}</span>
        <div className="float-right opacity-50 text-[12px] ml-[5px]">{request.status}</div>
      </div>
    </>
  );
}

export default Execution;
