import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  // prod
  apiKey: "AIzaSyDmpnVL_TGlqZk3RScSCgijtyW_x6_JlD8",
  authDomain: "altvestment.firebaseapp.com",
  projectId: "altvestment",
  appId: "1:1046550148268:web:bc0ed4b2d8fea31ace5b09",
  measurementId: "G-18KFZXPNE0",

  // test
  // apiKey: "AIzaSyDAVtvKPmh2qnMT4Mc7Qyac0SWNGceN5rA",
  // authDomain: "altvestest.firebaseapp.com",
  // projectId: "altvestest",
  // appId: "1:464191435696:web:4caf46782d1cd9862d9c75",
  // measurementId: "G-Y6LN2PT8V4",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
