import React from 'react';

const Modal = ({ isOpen, setIsOpen, children }) => {
  return (
    <div className={`${isOpen ? "" : "hidden"} fixed inset-0 z-50 overflow-auto backdrop-blur`}>
      <div className="relative p-4 mx-auto w-full max-w-xl">
        <div className="bg-[#0E1A44] rounded shadow-lg py-[48px]">
          <div className="px-4 text-white">
            {children}
          </div>
          <svg className="absolute top-[37px] right-[37px] cursor-pointer" onClick={() => setIsOpen(false)} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#E6E7EB" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Modal;
